/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 * Modified by Stamat <stamatmail@gmail.com>
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 function setCookie(cname, cvalue, exdays) {
     var d = new Date();
     d.setTime(d.getTime() + (exdays*24*60*60*1000));
     var expires = "expires="+ d.toUTCString();
     document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
 }

 function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
 }

 function imageLoader(src, callback, once) {
     function load(src, callback) {
         var img = new Image();
         if (callback) {
             img.addEventListener('load', callback, false);
         }
         img.src = src;
     }

     var onced = false;

     if ($.isArray(src)) {
         for (var i = 0; i < src.length; i++) {
             var fn = onced && once ? null : callback;
             load(src[i], fn);
             onced = true;
         }
         return;
     }

     load(src, callback);
 }

 function imageLoadFade(elem) {
     var $elem = $(elem);
     var src = $elem.data('full');

     imageLoader(src, function(e) {
         $elem.css({'background-image': 'url('+src+')'});
     });
 }

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var boilerwise = {
    // All pages
    init: function() {
      // JavaScript to be fired on all pages
      $('.nav .menu-button.w-nav-button').on('click', function(e) {
            $('body').toggleClass('menu-open');
      });

      var $images_to_load = $('.loadme');
       for (var i = 0; i < $images_to_load.length; i++) {
           var $img = $images_to_load[i];
           imageLoadFade($img);
       }

      $('.filter-block .root h5').on('click', function(e) {
            $(this).parent().find('.sub-blocks').slideToggle();
            $(this).parent().toggleClass('active');
      });

      $('.term-block .term h5').on('click', function(e) {

            if (!$(this).parent().hasClass('active')) {
                var $prev = $(this).closest('.term-block').find('.active');
                $prev.find('.p.small').slideUp();
                $prev.removeClass('active');

                $(this).parent().addClass('active');
                $(this).parent().find('.p.small').slideDown();
            } else {
                $(this).parent().removeClass('active');
                $(this).parent().find('.p.small').slideUp();
            }

      });

      //$('.filter-block .root .sub-blocks').slideUp();
      $('.term-block .term .p.small').slideUp();
      $('.filter-block .visible').stop(true).fadeOut();

      //$('.filter-block .root-blocks').slideUp();
      $('.filter-block .main .filter-small-text, .filter-block .main i').on('click', function(e) {
            $(this).closest('.filter-block').find('.root-blocks').slideToggle();
            $(this).parent().toggleClass('active');
      });

      if (getCookie('popup') === '') {
          $('body').removeClass('no-popup');
      }

      $('#yes').on('click', function(e){
          e.preventDefault();
          setCookie('popup', 'true', 356);
          $('body').addClass('no-popup');
      });

      var filters = [];

      $('.filter-block .root .sub-blocks .filter-small-text').on('click', function(e) {
            $(this).parent().toggleClass('active');

            if ($(this).parent().hasClass('active')) {
                filters.push($(this).data('filter'));
            } else {
                var i = filters.indexOf($(this).data('filter'));
                if (i > -1) {
                    filters.splice(i, 1);
                }
            }

            var f = filters.join(',');
            $('.filter-block #count .value').html(filters.length);

            if (filters.length) {
                $('.product-wrap').not(f).stop(true).hide();
                $(f).stop(true).show();
                $('.filter-block .visible').stop(true).fadeIn();
            } else {
                $('.product-wrap').stop(true).show();
                $('.filter-block .visible').stop(true).fadeOut();
            }

      });

      function clearFilters() {
          $('.product-wrap').stop(true).show();
          $('.filter-block .visible').stop(true).fadeOut();
          filters = [];
          $('.filter-block #count .value').html(0);
          $('.filter-block .root .sub-blocks .sub').removeClass('active');
      }

      $('.price-block .size').on('click', function(e) {
          e.stopPropagation();
          e.preventDefault();

          var price = $(this).data('price');
          var variation = $(this).text();
          $(this).parent().find('.active').removeClass('active');
          $(this).addClass('active');

          $(this).closest('.price-block').find('.price').html(price);
          $(this).closest('.price-block').find('.variation').html(variation);
      });

      $('.filter-block #clear-all, .filter-block #all').on('click', function(e) {
          e.stopPropagation();
          e.preventDefault();

            clearFilters();
      });

      $(window).on('resize', function(e) {
            if (window.innerWidth > 991 && $('body').hasClass('menu-open')) {
                $('body').removeClass('menu-open');
            }
      });

      $(".scroll-to").click(function() {
        $('html, body').animate({
                scrollTop: $("#"+$(this).data('to')).offset().top
            }, 500);
        });

      $('.slick').slick({
          centerMode: false,
          slidesToShow: 3,
          arrows: true,
          dots: true,
          infinite: true,
          slidesToScroll: 3,

          responsive: [
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
          },
          {
            breakpoint: 479,
            settings: {
              centerMode: true,
              centerPadding: '20px',
              slidesToShow: 1,
              slidesToScroll: 1
            }
        }

          ]
        });

        $('.slick-one').slick({
            centerMode: false,
            slidesToShow: 1,
            arrows: true,
            dots: true,
            infinite: true,
            slidesToScroll: 1
        });

        function getInstPhotos() {
            var url = 'https://api.instagram.com/v1/users/self/media/recent?access_token=' + window.INSTAGRAM.token + '&count=' + window.INSTAGRAM.count + '&callback=';
            var promise = $.ajax(url, {
              dataType: 'jsonp'
            });

            return promise.then(function (response) {
              if (response.meta.code != 200) {
                var error = response.meta.error_message + ' (' + response.meta.error_type + ')';
                return $.Deferred().reject(error).promise();
              }

              return response.data;
            });
        }

        function renderInstPhotos() {
            var $instagram_section = $('#instagram-section');
            var $template = $('[data-instagram-photo-template]', $instagram_section);
            $template.removeAttr('data-instagram-photo-template');
            var $container = $('[data-instagram-photos]', $instagram_section);

            getInstPhotos().done(function (photos) {
              var photo_array = [];
              var $photo_slide = $('<div class="inst-slide" />');
              $container.addClass('instagram-photo-count-' + photos.length);

              for (var i = 1; i <= photos.length; i++) {
                  var photo = photos[i-1];

                  var $t = $template.clone();
                  $t.removeClass('template');

                  var date = new Date(photo.created_time * 1000);
                  var dateOptions = {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric'
                  };
                  var likes = photo.likes.count;
                  var timestamp = date.toLocaleDateString(window.INSTAGRAM.locale, dateOptions);
                  var caption = photo.caption ? photo.caption.text : '';
                  var $link = $('<a target="_blank" class="overlay-link" href="' + photo.link + '"></a>');
                  var $photo = $('<div class="image" style="background-image: url(' + photo.images.standard_resolution.url + ')" />');

                  $t.find('[data-instagram-photo-template-likes]').append(likes).removeAttr('data-instagram-photo-template-likes');
                  $t.find('[data-instagram-photo-template-caption]').append(caption).removeAttr('data-instagram-photo-template-caption');
                  $t.find('[data-instagram-photo-template-timestamp]').append(timestamp).removeAttr('data-instagram-photo-template-timestamp');
                  $t.find('.insta-content').append($link);
                  $t.find('.insta-content').append($photo);

                  photo_array.push($t);
              }

              $container.append(photo_array);
              $container.addClass('loaded');
              $template.remove();

               // $container.not('.slick-initialized').slick({
               //     dots: false,
               //     arrows: true,
               //     slidesToShow: 1
               // });

               $container.addClass('slick').slick({
                   centerMode: false,
                   slidesToShow: 3,
                   arrows: true,
                   dots: true,
                   infinite: true,
                   slidesToScroll: 3,

                   responsive: [
                     {
                       breakpoint: 767,
                       settings: {
                         slidesToShow: 2,
                         slidesToScroll: 2
                       }
                   },
                   {
                     breakpoint: 479,
                     settings: {
                       centerMode: true,
                       centerPadding: '20px',
                       slidesToShow: 1,
                       slidesToScroll: 1
                     }
                 }

                   ]
                 });
            }).fail(function (error) {
              console.warn('Instagram: ' + error);
            });
        }

        if (window.hasOwnProperty('INSTAGRAM') && window.INSTAGRAM.token != '' ) {
            renderInstPhotos();
        }
    },
    finalize: function(a, b, c) {
      // JavaScript to be fired on all pages, after page specific JS after everything else was executed
    },
    // Home page
    'home': function() {
        // JavaScript to be fired on the home page
    },
    // About us page, note the change from about-us to about_us.
    'about_us': function() {
        // JavaScript to be fired on the about us page
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {};

  UTIL.namespace = boilerwise;

  // Parses arguments in body's attribute data-args that are passed to the functions
  // Arguments are coma sepparated like in array without the need to begin and end with []
  UTIL.argsParse = function(args) {
    if (!args || args.replace(/^\s\s*/, '').replace(/\s\s*$/, '') === '') {
      return [];
    }

    if (!args.match(/^\[/gmi)) {
      args = '[' + args;
    }

    if (!args.match(/$\]/gmi)) {
      args = args + ']';
    }

    args = '{ "arr": ' + args + '}';

    try {
      args = JSON.parse(args);
    } catch(e) {
      if (window.console && window.console.error) {
        console.error(e);
      }
      return [];
    }


    return args.arr;
  };

  UTIL.fire = function(func, args) {
    var namespace = UTIL.namespace;

    if (func !== '' &&
        namespace[func] &&
        typeof namespace[func] === 'function') {

      namespace[func].apply(null, args);
    }
  };

  UTIL.loadEvents= function() {
    var body = document.body;
    var args = UTIL.argsParse($(body).data('args'));

    // Fire common init JS
    UTIL.fire('init', args);

    // Fire page-specific init JS, and then finalize JS
    $.each(body.className.split(/\s+/), function(i, classnm) {
      //todo: get data args

      UTIL.fire(classnm.replace(/-/g, '_'), args);
    });

    // Fire common finalize JS
    UTIL.fire('finalize', args);
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
